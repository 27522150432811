
import React, { useEffect, useState } from "react"
import './styles.css'

export default function Service(props) {

    const [sources, setSources] = useState({
        title: undefined,
        image: undefined,
        titleColor: '#2e455c',
    })
    const [common, setCommon] = useState({})

    useEffect(() => {

        setSources({
            title: props.title,
            image: props.image,
            titleColor: props.titleColor,
            action: props.action ? props.action : () => {}
        })
        
        const uncommon = ['title', 'image', 'titleColor', 'action']
        const com = {...props}
        uncommon.forEach( prop => delete com[prop])

        setCommon(com)

    }, [props, setSources, setCommon])

    return (
        <div className="service-container" {...common} >
            <div className="service-image-container" onClick={ sources.action }>{ sources.image }</div> 
            <h1 className="service-title" style={{ color: sources.titleColor }} >{ sources.title }</h1>
        </div>
    )
}