
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import RequestForProposal from './components/RequestForProposal'
import NotFound from './components/NotFound'
import FormViewer from './components/FormViewer';
import Services from './components/Services';
import Policy from './components/Policy';
import Terms from './components/Terms';
 
export default function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={ <Home /> } />
                <Route path="/about" element={ <About /> }/>
                <Route path='/contact' element={ <Contact /> } />
                <Route path='/requestForProposal' element={ <RequestForProposal /> } />
                <Route path='/form' element={ <FormViewer /> } />
                <Route path='/services' element={ <Services /> } />
                <Route path='/policy' element={ <Policy /> } />
                <Route path='/terms' element={ <Terms /> } />
                <Route path='*' Component={ NotFound }/>
            </Routes>
        </Router>
    )
}