
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.css'

import Logo from '../../assets/common-svg-files/logo-primordial.svg'

export default function Nav({ backgroundColor }) {

    const navigate = useNavigate()
    
    const goToHomePage = () => {
        navigate('/')
    }

    const goToContact = () => {
        navigate('/contact')
    }

    const goToServices = () => {
        navigate('/services')
    }

    const goToAboutPage = () => {
        navigate('/about')
    }

    const SCROLL_RESPONSE_TIME = 500

    const lastScrollState = useRef({
        movement: 'idle',
        top: 0,
        time: Date.now() - SCROLL_RESPONSE_TIME
    })

    const [visibility, setVisibility] = useState('show')

    useEffect(() => {

        const handleScroll = _ => {

            let scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > lastScrollState.current.top) {
                lastScrollState.current.movement = 'down'
            } else {
                lastScrollState.current.movement = 'up'
            }

            if (Date.now() - lastScrollState.current.time > SCROLL_RESPONSE_TIME) {
                
                setVisibility(lastScrollState.current.movement === 'down' ? 'hide' : 'show')

                lastScrollState.current.time = Date.now()
            }

            lastScrollState.current.top = scrollTop <= 0 ? 0 : scrollTop;
        }

        document.addEventListener('scroll', handleScroll)

        return () => {
            document.removeEventListener('scroll', handleScroll)
        }

    })

    const state = useRef(false)
    const buttonRef = useRef(null)
    const itemsRef = useRef(null)

    const handleClick = () => {
        if (!buttonRef.current || !itemsRef.current ) { return }

        if (state.current) {
            buttonRef.current.classList.remove('button-active')
            itemsRef.current.style = 'transform: scaleY(0)'
        } else {
            buttonRef.current.classList.add('button-active')
            itemsRef.current.style = 'transform: scaleY(1)'
        }

        state.current = !state.current
    }

    return (
        <div className="nav-bar" id='nav-bar' style={{
            top: visibility === 'show' ? 0 : `-100%` ,
        }}>
            <img src={ Logo } className="logo default-button" id='nav-bar-logo' onClick={ goToHomePage } alt='logo da primordial' />

            <div className='mobile-button' onClick={ handleClick } ref={ buttonRef }>
                <div className='line first'></div>
                <div className='line second'></div>
                <div className='line third'></div>
            </div>

            <div className='items-container'  id='nav-bar-items-container' ref={ itemsRef } >
                <div className='item default-button' onClick={ goToServices }>Serviços</div>
                <div className='item default-button' onClick={ goToAboutPage }>Sobre</div>
                <div className='item default-button' onClick={ goToContact } >Contato</div>
            </div>

        </div>
    )
}