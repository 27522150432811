
import React, { useEffect, useState } from "react"
const SvgComponent = (props) => { 
    
    const [propertys, setPropertys] = useState({})

    useEffect(() => {

        const commonProps = {...props}
        const uncommon = ['stroke', 'strokeWidth']
        uncommon.forEach( prop => {
            delete commonProps[prop]
        })

        setPropertys(commonProps)

    }, [props, setPropertys])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            transform="rotate(90)"
            viewBox="0 0 24 24"
            {...propertys}
        >
            <g stroke={props.stroke ? props.stroke : "#222"} strokeWidth={props.strokeWidth ? props.strokeWidth : 1.5 }>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m17 7-5 5m0 0h3.75M12 12V8.25"
            />
            <path d="M2 18c0-1.886 0-2.828.586-3.414C3.172 14 4.114 14 6 14c1.886 0 2.828 0 3.414.586C10 15.172 10 16.114 10 18c0 1.886 0 2.828-.586 3.414C8.828 22 7.886 22 6 22c-1.886 0-2.828 0-3.414-.586C2 20.828 2 19.886 2 18Z" />
            <path
                strokeLinecap="round"
                d="M12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.178 21.893 17.055 21.992 13 22M2 11c.008-4.055.107-6.178 1.464-7.536.974-.973 2.342-1.3 4.536-1.409"
            />
            </g>
        </svg>
    )
}

export default SvgComponent
