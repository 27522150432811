
import './styles.css'
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import GLOBAL from '../../services/GLOBAL';

function FormViewer() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const navigate = useNavigate()

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const [id] = useState(urlParams.get('id'))
    const [pass, setPass] = useState(localStorage.getItem('viewer_access'))
    const [content, setContent] = useState()

    useEffect(() => {

        if (!pass) {
            return
        }

        const fetchJson = async () => {
            try {
                const response = await fetch(`${GLOBAL.BACKEND_BASE_URL}/jsons/${id}.json`, {
                    headers: {
                        'Authorization': pass
                    }
                })

                if (response.status === 401) {
                    localStorage.removeItem('viewer_access')
                    setPass(null)
                    return
                }

                if (!response.ok) {
                    navigate('/404')
                    throw new Error('Failed to fetch JSON')
                }
                
                setContent(await response.json())

            } catch (error) {
                console.error('Error fetching JSON:', error)
            }
        }

        fetchJson()

    }, [id, pass, navigate, setPass])

    const downloadPdf = async () => {
        try {
            const response = await fetch(`${GLOBAL.BACKEND_BASE_URL}/pdfs/${id}.pdf`, {
                headers: {
                    'Authorization': pass
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch PDF');
            }

            const blob = await response.blob();
            const pdfBlobUrl = URL.createObjectURL(blob);

            const downloadLink = document.createElement('a');
            downloadLink.href = pdfBlobUrl;
            downloadLink.setAttribute('download', `${id}.pdf`);
            downloadLink.click();

        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <div className='form-viewer-container' >
            { 
                pass ? 

                    <div className='sheet-container'>
                        <div className='form-data'>
                            {content ? (
                                content.map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))
                            ) : null}

                        <button onClick={downloadPdf} className='default-button'>Baixar como PDF</button>
                        
                        </div>

                    </div>

                : <AccessInput setPass={setPass} />
            }
        </div>
  );

}

function AccessInput({ setPass }) {

    const passRef = useRef()

    const handleSubmit = (e) => {
        e.preventDefault()
        setPass(passRef.current.value)
        localStorage.setItem('viewer_access', passRef.current.value)
    }

    return (
        <form className='access-input-container' onSubmit={ handleSubmit }>
            <label htmlFor='viewer_pass'>Digite a senha de acesso</label>
            <input ref={passRef}
                id='viewer_pass'
                type='password'
                placeholder='senha'
                autoComplete='current-password'
            />
            <input type='submit' name='submit' value='VER PDF' />
        </form>
    )
}

export default FormViewer;
