
import axios from 'axios'
import GLOBAL from './GLOBAL'

const api = axios.create({
    baseURL: GLOBAL.BACKEND_BASE_URL,
    headers: {
        'Content-Type':'application/json'
    }
})

export default api
