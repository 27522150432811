import './styles.css'

import Footer from '../Footer'
import Nav from '../Nav'
import { useEffect, useState } from 'react'

import GLOBAL from '../../services/GLOBAL.js'

export default function Terms() {

    const [contactInfo, setContactInfo] = useState({address: '', email: '', phone_number: ''})

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchContactInfo = async () => {
            try {
                const { contact_info } = await GLOBAL.CONTENT;
                setContactInfo(contact_info);
            } catch (error) {
                console.error('Failed to fetch Contact Info data:', error);
            }
        };

        fetchContactInfo();
    }, [])

    return (
        <div className='terms-container'>
            <Nav />
            
            <div className='terms-content'>

                <h1>Termos de Uso</h1>

                <div>
                    <h2>1. Aceitação dos Termos:</h2>
                    <p>
                        Ao acessar e utilizar o site da Primordial Consultoria, você concorda em cumprir e ficar vinculado aos&nbsp;
                        seguintes termos e condições de uso. Caso não concorde com algum desses termos, você não deve usar este site.
                    </p>
                </div>

                <div>
                    <h2>2. Modificações dos Termos:</h2>
                    <p>
                        A Primordial Consultoria reserva-se o direito de alterar estes termos a qualquer momento, sem aviso prévio. &nbsp;
                        As modificações serão efetivas a partir da publicação no site. Recomendamos que você revise periodicamente esta&nbsp;
                        página para estar ciente de quaisquer mudanças.
                    </p>
                </div>

                <div>
                    <h2>3. Uso do Site:</h2>
                    <p>
                        Você concorda em usar o site apenas para fins legais e de acordo com os presentes Termos de Uso. Você se compromete a não:
                    </p>
                    <ul>
                        <li>
                            Publicar ou transmitir qualquer conteúdo ilegal, ameaçador, difamatório, obsceno, escandaloso,&nbsp;
                            inflamatório, pornográfico ou profano.
                        </li>
                        <li>Violar qualquer direito de propriedade intelectual, incluindo direitos autorais e marcas registradas.</li>
                        <li>Usar o site para transmitir ou enviar materiais publicitários ou promocionais não solicitados ("spam").</li>
                        <li>Tentar obter acesso não autorizado ao site, servidores ou redes conectadas ao site.</li>
                    </ul>
                </div>

                <div>
                    <h2>4. Propriedade Intelectual:</h2>
                    <p>
                        Todo o conteúdo do site, incluindo, mas não se limitando a, textos, gráficos, logotipos, ícones, imagens e&nbsp;
                        software, é de propriedade da Primordial Consultoria ou de seus licenciadores e está protegido por leis de&nbsp;
                        direitos autorais e outras leis de propriedade intelectual.
                    </p>
                </div>

                <div>
                    <h2>5. Limitação de Responsabilidade:</h2>
                    <p>
                        A Primordial Consultoria não será responsável por quaisquer danos diretos, indiretos, incidentais,&nbsp;
                        consequenciais ou punitivos decorrentes do uso ou da incapacidade de uso deste site, mesmo que a Primordial&nbsp;
                        Consultoria tenha sido avisada da possibilidade de tais danos.
                    </p>
                </div>

                <div>
                    <h2>6. Links para Sites de Terceiros:</h2>
                    <p>
                        Este site pode conter links para outros sites que não são controlados pela Primordial Consultoria. Não nos&nbsp;
                        responsabilizamos pelo conteúdo desses sites de terceiros. A inclusão de quaisquer links não implica&nbsp;
                        endosso por parte da Primordial Consultoria.
                    </p>
                </div>

                <div>
                    <h2>7. Política de Privacidade:</h2>
                    <p>
                        Sua privacidade é importante para nós. Consulte nossa Política de Privacidade para obter informações&nbsp;
                        detalhadas sobre como coletamos, usamos e protegemos suas informações pessoais.
                    </p>
                </div>

                <div>
                    <h2>8. Lei Aplicável:</h2>
                    <p>
                        Estes Termos de Uso serão regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa&nbsp;
                        decorrente destes termos será submetida exclusivamente aos tribunais competentes no Brasil.
                    </p>
                </div>

                <div>
                    <h2>9. Contato:</h2>
                    <p>
                        Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco através do e-mail: { contactInfo.email }.
                    </p>
                </div>

            </div>

            <Footer />
        </div>
    )
}
