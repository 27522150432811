
import React, { useEffect, useState, useRef } from 'react'

export default function LazyImage(props) {

    const [commonProps, setCommonProps] = useState(null)

    const [sources, setSources] = useState({
        thumbnail: undefined,
        lightSource: undefined,
        heavySource: undefined,
        imgStyle: undefined,
        baseURL: ''
    })

    /**
     * -1 = Starting
     * 0 = thumbnail / initial
     * 1 = light source loaded / intermediate
     * 2 = heavy source loaded / complete
     */
    const [loadState, setLoadState] = useState(-1)

    useEffect(() => {

        const { thumbnail, lightSource, heavySource, baseURL } = props
        const new_commonProps = {...props}
    
        const uncommonProps = ['thumbnail', 'lightSource', 'heavySource', 'baseURL']
        uncommonProps.forEach(prop => {
            delete new_commonProps[prop];
        });

        setCommonProps(new_commonProps)
        setSources({ thumbnail, lightSource, heavySource, baseURL })

    }, [props, setCommonProps, setSources, loadState])

    const thumbImg = useRef()
    const lightImg = useRef()
    const heavyImg = useRef()

    const imgStyle = {
        
        width: '100%',
        height: '100%',
        display: 'block',
        marginTop: '0', 
        marginLeft: '0',

    }

    return (
        <div { ...commonProps } >

                <img
                    alt={ props.alt }
                    src={ sources.thumbnail }
                    style={{
                        ...imgStyle,
                        display: loadState === 0 ? 'block' : 'none'
                    }}
                    ref={ thumbImg }
                    onLoad={ () => {
                        setLoadState( prev => prev + 1)
                    }}
                />

                <img
                    alt={ props.alt }
                    src={ loadState >= 0 ? `${ sources.baseURL}/${ sources.lightSource }` : '' }
                    style={{
                        ...imgStyle,
                        display: loadState === 1 ? 'block' : 'none'
                    }}
                    ref={ lightImg }
                    onLoad={ () => {
                        setLoadState( prev => prev + 1)
                    }}
                />

                <img
                    alt={ props.alt }
                    src={ loadState >= 1 ? `${ sources.baseURL}/${ sources.heavySource }` : '' }
                    style={{
                        ...imgStyle,
                        display: loadState === 2 ? 'block' : 'none'
                    }}
                    ref={ heavyImg }
                    onLoad={ () => {
                        setLoadState( prev => prev + 1)
                    }}
                />

        </div>
    )
}