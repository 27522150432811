
import './styles.css'

import Footer from '../Footer'
import Nav from '../Nav'
import { useEffect, useState } from 'react'
import GLOBAL from '../../services/GLOBAL.js'

export default function Policy() {

    const [contactInfo, setContactInfo] = useState({address: '', email: '', phone_number: ''})

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchContactInfo = async () => {
            try {
                const { contact_info } = await GLOBAL.CONTENT;
                setContactInfo(contact_info);
            } catch (error) {
                console.error('Failed to fetch Contact Info data:', error);
            }
        };

        fetchContactInfo();
    }, [])

    return (
        <div className='policy-container'>
            <Nav />
            
            <div className='policy-content'>

                <h1>Política de Privacidade</h1>

                <div>
                    <h2>1. Introdução:</h2>
                    <p>
                        A Primordial Consultoria valoriza a sua privacidade e se compromete a proteger as informações pessoais&nbsp;
                        que você compartilha conosco. Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e&nbsp;
                        protegemos suas informações pessoais.
                    </p>
                </div>

                <div>
                    <h2>2. Informações que Coletamos:</h2>

                    <p>
                        a. Informações que Você Fornece <br/>
                        Coletamos informações que você nos fornece diretamente, como:
                    </p>
                    <ul>
                        <li>Nome</li>
                        <li>Endereço de E-mail</li>
                        <li>Número de Telefone</li>
                        <li>Informações de Contato</li>
                        <li>Informações fornecidas ao preencher formulários no nosso site</li>
                    </ul>

                    <p>
                        b. Informações Coletadas Automaticamente <br/>
                        Quando você visita nosso site, podemos coletar automaticamente certas informações sobre o seu dispositivo e&nbsp;
                        uso do site, incluindo:
                    </p>
                    <ul>
                        <li>Endereço IP</li>
                        <li>Tipo de navegador</li>
                        <li>Páginas visitadas</li>
                        <li>Tempo gasto em cada página</li>
                        <li>Dados de cookies</li>
                    </ul>

                </div>

                <div>
                    <h2>3. Uso das Informações:</h2>
                    <p>
                        Utilizamos as informações coletadas para os seguintes fins:
                    </p>
                    <ul>
                        <li>Fornecer, operar e manter nosso site e serviços</li>
                        <li>Melhorar, personalizar e expandir nosso site e serviços</li>
                        <li>Entender e analisar como você usa nosso site e serviços</li>
                        <li>
                            Comunicar com você, diretamente ou através de um de nossos parceiros, inclusive para atendimento ao cliente,&nbsp;
                            para fornecer atualizações e outras informações relacionadas ao site e para fins de marketing e promocionais
                        </li>
                        <li>Processar suas transações e enviar informações relacionadas, incluindo confirmações de transação e faturas</li>
                        <li>Encontrar e prevenir fraudes</li>
                    </ul>
                </div>

                <div>
                    <h2>4. Compartilhamento de Informações:</h2>
                    <p>
                        Não vendemos, trocamos ou transferimos para terceiros suas informações pessoais identificáveis, exceto nos casos&nbsp;
                        descritos abaixo:
                    </p>
                    <ul>
                        <li>
                            Com prestadores de serviços que ajudam a operar nosso site, conduzir nossos negócios ou atender você, desde que&nbsp;
                            esses terceiros concordem em manter essas informações confidenciais
                        </li>
                        <li>
                            Quando acreditarmos que a liberação é apropriada para cumprir a lei, fazer cumprir as políticas do nosso site ou&nbsp;
                            proteger nossos direitos, propriedade ou segurança ou de terceiros
                        </li>
                    </ul>
                </div>

                <div>
                    <h2>5. Segurança das Informações:</h2>
                    <p>
                        Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração,&nbsp;
                        divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou método de armazenamento eletrônico&nbsp;
                        é 100% seguro.
                    </p>
                </div>

                <div>
                    <h2>6. Seus Direitos:</h2>
                    <p>
                        Dependendo da sua localização, você pode ter os seguintes direitos em relação às suas informações pessoais:
                    </p>
                    <ul>
                        <li>Direito de acesso às suas informações pessoais</li>
                        <li>Direito de retificação de quaisquer informações pessoais imprecisas</li>
                        <li>Direito de exclusão de suas informações pessoais</li>
                        <li>Direito de restringir ou objetar o processamento de suas informações pessoais</li>
                        <li>Direito à portabilidade dos dados</li>
                    </ul>
                    <p>
                        Para exercer esses direitos, entre em contato conosco usando as informações de contato fornecidas abaixo.
                    </p>
                </div>

                <div>
                    <h2>7. Cookies:</h2>
                    <p>
                        Utilizamos cookies para melhorar a sua experiência em nosso site. Cookies são pequenos arquivos de texto armazenados no seu&nbsp;
                        dispositivo que ajudam a analisar o tráfego do site ou lembrar de preferências de navegação. Você pode optar por desativar&nbsp;
                        os cookies através das configurações do seu navegador.
                    </p>
                </div>

                <div>
                    <h2>8. Alterações a esta Política de Privacidade:</h2>
                    <p>
                        Podemos atualizar nossa Política de Privacidade periodicamente. Notificaremos você sobre quaisquer mudanças publicando a nova&nbsp;
                        Política de Privacidade nesta página. Recomendamos que você reveja esta Política de Privacidade periodicamente para quaisquer&nbsp;
                        alterações.
                    </p>
                </div>

                <div>
                    <h2>9. Contato:</h2>
                    <p>
                        Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco:
                    </p>
                    <ul>
                        <li>E-mail: { contactInfo.email }</li>
                        <li>Telefone: { contactInfo.phone_number }</li>
                        <li>Endereço: { contactInfo.address }</li>
                    </ul>
                </div>

            </div>

            <Footer />
        </div>
    )
}