
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './styles.css'

import GLOBAL from '../../services/GLOBAL.js'

import Whatsapp from '../../SVGR/Whatsapp'
import Instagram from '../../SVGR/Instagram'
import Linkedin from '../../SVGR/Linkedin'

import logo from '../../assets/common-svg-files/logo-primordial-2.svg'

export default function Footer(props) {

    const [links, setLinks] = useState([])

    useEffect(() => {
        
        const fetchLinks = async () => {
            try {
                const { links } = await GLOBAL.CONTENT;
                setLinks(links);
            } catch (error) {
                console.error('Failed to fetch Links data:', error);
            }
        };

        fetchLinks();
    }, [])

    const navigate = useNavigate()

    return (
        <div className="footer-container">
            <div className="footer-content">

                <div className="company-container">
                    <img className="logo" alt="logo da primordial" src={ logo } />

                    <p className="content">
                        A Primordial Consultoria Ambiental é dedicada a fornecer soluções sustentáveis e personalizadas para&nbsp;
                        promover um futuro mais verde e saudável para todos.
                    </p>

                    <div className="social-medias">
                        <div className="item default-button" onClick={ _ => { window.open(links.whatsapp, "_blank") } }>
                            <Whatsapp fill='#f1f1f1' />
                        </div>
                        <div className="item default-button" onClick={ _ => { window.open(links.instagram, "_blank") } }>
                            <Instagram fill='#f1f1f1' />
                        </div>
                        <div className="item default-button" onClick={ _ => { window.open(links.linkedin, "_blank") } }>
                            <Linkedin fill='#f1f1f1' />
                        </div>
                    </div>
                </div>

                <div className="menu">
                    <h1 className="title">Menu</h1>

                    <div className="item default-button" onClick={ _ => navigate('/about') } >Sobre Nós</div>
                    <div className="item default-button" onClick={ _ => navigate('/services') } >Serviços</div>
                    <div className="item default-button" onClick={ _ => navigate('/contact') } >Contato</div>
                    <div className="item default-button" onClick={ _ => navigate('/policy') } >Política de Privacidade</div>
                    <div className="item default-button" onClick={ _ => navigate('/terms') } >Termos de Uso</div>
                </div>

            </div>
            <div className="copyright">© 2024 Primordial Consultoria Ambiental. Todos os direitos reservados.</div>
        </div>
    )
}