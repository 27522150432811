
import React, { useEffect, useState } from "react";
import "./styles.css";

import GLOBAL from '../../services/GLOBAL.js'

import Nav from '../Nav'
import Footer from "../Footer"
import LazyImage from "../../services/LazyImage";

import aboutPage from '../../assets/about-page.jpg'

export default function About() {

    const [colleagues, setColleagues] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchColleagues = async () => {
            try {
                const { colleagues } = await GLOBAL.CONTENT;
                setColleagues(colleagues);
            } catch (error) {
                console.error('Failed to fetch Colleagues data:', error);
            }
        };

        fetchColleagues();
    }, [])


    return (
        <div className="about-container">
            <Nav />
            <div className='hero-container'>

                <LazyImage 
                    className='image'
                    alt='Notebook sobre escrivaninha em frente à persianas'
                    thumbnail = { aboutPage }
                    lightSource = { 'images/about-page.jpg' }
                    heavySource = { 'images/about-page.png' }
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                />

                <div className="about-content-container">
                    <h1 className="title">Nossa História</h1>
                    <p className="content">
                        Na Primordial nosso compromisso é oferecer soluções personalizadas pois entendemos&nbsp;
                        cada cliente como único. Estamos comprometidos em ser parceiros de nossos clientes para&nbsp;
                        ajudá-los no longo prazo, inovando continuamente em nossa abordagem de trabalho para&nbsp;
                        atender às suas necessidades de forma exclusiva e criativa.
                    </p>
                </div>

            </div>

            <div className="our-team">
                <h1>Conheça nossa CEO</h1>

                <div className="team-grid">
                    {colleagues.map(colleague => 
                        <div className="colleague" key={colleague.name}>
                            <div className="image-container">
                                <img alt={colleague.name} src={`/images/${colleague.imageFileName}`} />
                            </div>
                            <h2>{colleague.name}</h2>
                            <p>{colleague.jobTitle}</p>
                        </div>
                    )}
                </div>

            </div>

            <Footer />
        </div>
    )
}