
import React, { useEffect, useState } from "react"
const SvgComponent = (props) => {
  
    const [propertys, setPropertys] = useState({})

    useEffect(() => {

        const commonProps = {...props}
        const uncommon = ['stroke', 'strokeWidth']
        uncommon.forEach( prop => {
            delete commonProps[prop]
        })

        setPropertys(commonProps)

    }, [props, setPropertys])
  
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox= { props.strokeWidth ?
                `${ 11 - (props.strokeWidth/2) } ${ 9 - (props.strokeWidth/2) } ${ 3 + props.strokeWidth } ${ 6 + props.strokeWidth }`
                : "10 8 5 8" }
            {...propertys}
        >
          <path
              stroke={ props.stroke ? props.stroke : "#323232" }
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={ props.strokeWidth ? props.strokeWidth : 2}
              d="m11 15 2.7-2.7v0a.425.425 0 0 0 0-.6v0L11 9"
          />
        </svg>
    )

}

export default SvgComponent
