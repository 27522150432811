
const envs = ['development', 'stage', 'production']
const ENV = envs[2]

const BACKEND_BASE_URL = 
    ENV === 'development' ? 'http://localhost:5000'
    : ENV === 'stage' ? ''
    : ENV === 'production' ? 'https://primordialconsultoria.com'
    : ''

const FRONTEND_BASE_URL = 
    ENV === 'development' ? 'https://localhost:3000'
    : ENV === 'stage' ? ''
    : ENV === 'production' ? 'https://primordialconsultoria.com'
    : ''

const CONTENT = new Promise( (resolve, reject) => {
    fetchAllJsonData().then(jsonData => {
        resolve(jsonData)
    }).catch( err => {
        reject(err)
    })
})

const WHATSAPP_LINK = new Promise( resolve => {
    CONTENT.then(({links}) => resolve(links.whatsapp)).catch( err => resolve('https://api.whatsapp.com/') )
})

const config = {
    ENV,
    BACKEND_BASE_URL,
    WHATSAPP_LINK,
    FRONTEND_BASE_URL,
    CONTENT
}

export default config
  
function fetchAllJsonData() {
    return Promise.all([
        fetchJson(BACKEND_BASE_URL+'/backend/content/colleagues.json'),
        fetchJson(BACKEND_BASE_URL+'/backend/content/contact-info.json'),
        fetchJson(BACKEND_BASE_URL+'/backend/content/faq.json'),
        fetchJson(BACKEND_BASE_URL+'/backend/content/feedbacks.json'),
        fetchJson(BACKEND_BASE_URL+'/backend/content/links.json'),
        fetchJson(BACKEND_BASE_URL+'/backend/content/services-list.json'),
    ])
    .then(([colleagues, contact_info, faq, feedbacks, links, services_list]) => {
        return {
            colleagues,
            contact_info,
            faq,
            feedbacks,
            links,
            services_list
        };
    })
    .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    });
};

function fetchJson(url) {
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Network response was not ok for ${url}`);
            }
            return response.json();
    });
};