
import React from "react";
import "./styles.css";

export default function NotFound() {

    return (
        <div>
            Page not found.
        </div>
    )
}