
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

import api from '../../services/api'
import GLOBAL from "../../services/GLOBAL";

import Nav from '../Nav'
import Footer from '../Footer'

export default function RequestForProposal() {

    const [whatsappLink, setWhatsappLink] = useState('')
    const [services, setServices] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchLink = async () => {
            try {
                setWhatsappLink(await GLOBAL.WHATSAPP_LINK);
            } catch (error) {
                console.error('Failed to Whatsapp Link data:', error);
            }
        };

        const fetchServices = async () => {
            try {
                const { services_list } = await GLOBAL.CONTENT;
                setServices(Object.values(services_list).reduce((list, group) => [...list, ...group], []));
            } catch (error) {
                console.error('Failed to Whatsapp Link data:', error);
            }
        };

        fetchLink();
        fetchServices();
    }, [])

    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        jobTitle: {},
        otherJobTitle: '',
        companyName: '',
        companyIndustry: '',
        companySite: '',
        employeesCount: {},
        budget: '',
        referralSource: '',
        deadline: '',
        interestedInServices: {},
        currentChallenge: '',
        agree: false
    })

    const handleChange = (event, prop) => {
        setFormData( prev => ({ ...prev, [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }) )
    }

    const generateWhatsappMessage = (formId) => {
        const time = new Date().getHours()
        return encodeURIComponent(
`${time >= 18 ? 'Boa noite' : time >= 12 ? 'Boa tarde' : 'Bom dia'}! Acabei de acessar seu site e preenchi o formulário:

${GLOBAL.FRONTEND_BASE_URL}/form?id=${formId}
`
    )}

    const generateFormText = () => { return (
        `${new Date().toLocaleString()}\n\n` +

        `1 - Informação de contato:\n` +
        `- Nome: ${ formData.name }\n` +
        `- E-mail: ${ formData.email }\n` +
        `- Whatsapp: ${ formData.phone }\n` +
        `- Cargo: ${ formData.otherJobTitle ? formData.otherJobTitle : Object.keys(formData.jobTitle)[0] }\n\n` +

        `2 - Informações da empresa:\n` +
        `- Nome da empresa: ${ formData.companyName }\n` +
        `- Setor: ${ formData.companyIndustry }\n` +
        `- Site: ${ formData.companySite }\n` +
        `- Equipe: ${ Object.keys(formData.employeesCount)[0] }\n\n` +

        `3 - Detalhes do projeto:\n` +
        `- Quanto pretende investir: ${ formData.budget }\n` +
        `- Como nos encontrou: ${ formData.referralSource }\n` +
        `- Para quando precisa desse projeto: ${ formData.deadline }\n` +
        `- Serviços que tem interesse: ${ 
            Object.keys(formData.interestedInServices).reduce( (result, service, index, array) => {
                result += service

                if (index === array.length-1) {
                    result += '.'
                } else {
                    result += ', '
                }

                return result
            }, '')
        }\n\n` +

        `4 - Qual é a sua maior dificuldade hoje?\n` +
        `- Resposta: ${ formData.currentChallenge }\n\n` +

        `Corcorda com a Política de privacidade da Primordial: ${ formData.agree ? 'sim' : 'não' }`
    )}

    const handleContact = (e) => {
        e.preventDefault()

        api.post('/create-pdf', { text: generateFormText() } )
            .then( ({ data }) => {
                console.log('Form sent successfully.')
                window.open(whatsappLink+generateWhatsappMessage(data.id), '_blank')
                navigate(-1)
            } )
            .catch( _ => {
                console.log('Error sending form data.');
                alert('Não conseguimos enviar seu formulário. Tente novamente.')
            })
        
    }

    const handlePhone = (e) => {

        const phone = e.target.value
        const numericPhone = removeNonNumericChar(phone)

        if (numericPhone.length > 11) {
            return;
        }

        const formattedPhone = format(numericPhone)
        
        setFormData(prev => ({
            ...prev,
            phone: formattedPhone
        }))
    
        function removeNonNumericChar(phone) {
            return phone.replace(/\D/g, '');
        }

        function format(phone) {
            if (phone.length === 0) return '';

            let result = '';
            for (let i = 0; i < phone.length; i++) {
                if (i === 0) result += '(';
                if (i === 2) result += ') ';
                if (i === 7) result += '-';
                result += phone[i];
            }
            return result;
        }
    }

    const handleJobTitle = ( callback = prev => prev ) => {
        setFormData( prev => {
            const newJobTitle = callback(prev.jobTitle)
            return ({...prev, jobTitle: newJobTitle})
        })
    }


    const handleEmployeesCount = ( callback = prev => prev ) => {
        setFormData( prev => {
            const newEmployeesCount = callback(prev.employeesCount)
            return ({...prev, employeesCount: newEmployeesCount})
        })
    }


    const handleInterestedInServices = ( callback = prev => prev ) => {
        setFormData( prev => {
            const newInterestedInServices = callback(prev.interestedInServices)
            return ({...prev, interestedInServices: newInterestedInServices})
        })
    }

    return (
        <div className="rfp-container">
        <Nav />
        <form onSubmit={ handleContact }>
            
            <h1>Solicite uma Proposta</h1>
            <p>
                Preencha o formulário e mande-nos uma mensagem para receber uma proposta personalizada.
            </p>

            <div className="section">
                <h2>1 - Informações de Contato:</h2>

                <div className='grid-container'>

                    <input
                        type='text'
                        autoComplete='name'
                        id='name'
                        className="grid1"
                        value={ formData.name }
                        onChange={ e => handleChange(e, 'name') }
                        placeholder='Seu nome aqui'
                        required
                    />
                        
                    <input
                        className="grid2"
                        type='text'
                        autoComplete='email'
                        id='email'
                        value={ formData.email }
                        onChange={ e => handleChange(e, 'email') }
                        placeholder='E-mail'
                        required
                    />
                    
                    <input
                        className="grid3"
                        type="tel"
                        id="phone"
                        name="phone"
                        value={ formData.phone }
                        onChange={ handlePhone }
                        autoComplete="tel"
                        placeholder="DDD + Whatsapp"
                        pattern="\(\d{2}\) \d{5}-\d{4}"
                        required
                    />

                </div>

                <CheckboxList
                    checkMax={ 1 }
                    titles={[
                        'Eu sou CEO ou dono',
                        'Eu sou profissional da área ambiental',
                        'Eu sou profissional da área administrativa',
                        'Outro cargo'
                    ]}
                    data={ formData.jobTitle }
                    setData={ handleJobTitle }
                />

                { formData.jobTitle['Outro cargo'] ? 
                    <input className="other-job-inpt"
                        type="text"
                        id="other-job-inpt"
                        name="other-job-title"
                        value={ formData.otherJobTitle }
                        onChange={ e => handleChange(e, 'otherJobTitle') }
                        placeholder="Escreva aqui..."
                        required
                    />
                : null }

            </div>
            
            <div className="section">
                <h2>2 - Informações da empresa:</h2>

                <div className='grid-container'>

                    <input
                        className="grid1"
                        type='text'
                        id='companyName'
                        value={ formData.companyName }
                        onChange={ e => handleChange(e, 'companyName') }
                        placeholder='Nome da Empresa'
                        required
                    />
                        
                    <input
                        className="grid2"
                        type='text'
                        id='companyIndustry'
                        value={ formData.companyIndustry }
                        onChange={ e => handleChange(e, 'companyIndustry') }
                        placeholder='Setor'
                        required
                    />
                    
                    <input
                        className="grid3"
                        id="companySite"
                        name="companySite"
                        value={ formData.companySite }
                        onChange={ e => handleChange(e, 'companySite') }
                        placeholder='Site (opcional)'
                    />

                </div>

                <CheckboxList
                    checkMax={ 1 }
                    titles={[
                        'Nenhum funcionário (trabalho sozinho)',
                        '1 a 10 funcionários',
                        '11 a 30 funcionários',
                        '31 a 100 funcionários',
                        'Mais de 100 funcionários'
                    ]}
                    data={ formData.employeesCount }
                    setData={ handleEmployeesCount }
                />

            </div>
            
            <div className="section">
                <h2>3 - Detalhes do projeto:</h2>

                <div className='grid-container'>

                    <input type='currency' id='budget'
                        className="grid1"
                        value={ formData.budget }
                        onChange={ e => handleChange(e, 'budget') }
                        placeholder='Quanto pretende investir nesse projeto?'
                        required
                    />
                        
                    <input type='text' id='referralSource'
                        className="grid2"
                        value={ formData.referralSource }
                        onChange={ e => handleChange(e, 'referralSource') }
                        placeholder='Como nos encontrou?'
                        required
                    />
                    
                    <input
                        className="grid3"
                        id="deadline"
                        name="deadline"
                        value={ formData.deadline }
                        onChange={ e => handleChange(e, 'deadline') }
                        placeholder='Para quando precisa deste projeto?'
                        required
                    />

                </div>

                <h3>Selecione os serviços que você tem interesse:</h3>
                <CheckboxList
                    className='services-list'
                    titles={services.map( service => service.title )}
                    data={ formData.interestedInServices }
                    setData={ handleInterestedInServices }
                />

            </div>
            

            <div className="section">
                <h2>4 - Qual é a sua maior dificuldade hoje?</h2>

                <textarea
                    className="section-4-inpt"
                    type="text"
                    id="currentChallenge"
                    name="currentChallenge"
                    value={ formData.currentChallenge }
                    onChange={ e => handleChange(e, 'currentChallenge') }
                    placeholder='Escreva aqui...'
                    required
                />

            </div>
            
            <div className='checkbox-container' key={ randomHex() } >
                <input type='checkbox' id="agreement"
                    checked={ formData.agree } 
                    onChange={ e => handleChange(e, "agree") }
                    required
                />
                <label htmlFor={"agreement"}>Concordo com a Política de privacidade da Primordial</label>
            </div>
            
            <input id="submit" type='submit' className='submit default-button' value='Solicitar Proposta' />

        </form>
        <Footer />
        </div>
    )
}

function CheckboxList({ titles = [], data, setData, className, checkMax }) {

    const getIds = (titles) => titles.map( title => `${randomHex()}-${title}` )

    const [ids, setIds] = useState([])

    useEffect(() => {
        if (!titles) { return }
        setIds(getIds(titles))
    }, [titles, setIds])

    const [lastCheck, setLastCheck] = useState()

    const handleChange = (e, title) => {

        if (e.target.checked) {
            if (checkMax && countChecks() >= checkMax) {
                unCheck(lastCheck) 
            }
            
            check(title)
            setLastCheck(title)

        } else {
            unCheck(title)
        }


        function countChecks() {
            return Object.values(data).length
        }

        function check(title) {
            setData( prev => ({ ...prev, [title]: true }) )
        }

        function unCheck(title) {
            setData( prev => {
                const clone = { ...prev }
                delete clone[title]
                return clone
            })
        }

    }
    
    return (
        <div className={"checkbox-list " + (className ? className : '')}>
        
        { titles.map( (title, index) => 
            <div className='checkbox-container' key={ randomHex() } >
                <input type='checkbox' id={ids[index]}
                    checked={ data[title] } 
                    onChange={ e => handleChange(e, title) }
                />
                <label htmlFor={ids[index]}>{ title }</label>
            </div>
        )}
        
        </div>
    )
}

function randomHex () { return Math.round(Math.random()*1000000).toString(16) }