
import React, { useEffect } from 'react';
import './App.css';
import AppRoutes from './AppRoutes.jsx'

function App() {

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
          // Prevent browser from showing the install PWA pop-up
          e.preventDefault();
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }, []);

    return (
        <div className="App">
            <AppRoutes />
        </div>
    );
}

export default App;
