
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./styles.css";

import Nav from "../Nav";
import Footer from '../Footer'

import home_page from '../../assets/home-page.jpg'
import meta_sustentavel from '../../assets/meta-sustentavel.png'

import LazyImage from "../../services/LazyImage.jsx";

import Service from "../Service/index.jsx";

import GLOBAL from "../../services/GLOBAL.js";

export default function Home() {

    const [faqData, setFaqData] = useState([])
    const [feedbacks, setFeedbacks] = useState([])
    const [highlighteds, setHighlighteds] = useState()

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchFAQData = async () => {
            try {
                const { faq } = await GLOBAL.CONTENT;
                setFaqData(faq);
            } catch (error) {
                console.error('Failed to fetch FAQ data:', error);
            }
        };

        const fetchFeedbacks = async () => {
            try {
                const { feedbacks } = await GLOBAL.CONTENT;
                setFeedbacks(feedbacks);
            } catch (error) {
                console.error('Failed to fetch Feedbacks data:', error);
            }
        };

        const fetchServicesData = async () => {
            try {
                const { services_list } = await GLOBAL.CONTENT;
                setHighlighteds(Object.keys(services_list).slice(0, 2));
            } catch (error) {
                console.error('Failed to Services data:', error);
            }
        };

        fetchServicesData();
        fetchFAQData();
        fetchFeedbacks();
    }, [])


    const navigate = useNavigate()

    const goToContact = () => {
        navigate('/contact')
    }

    const goToRFP = () => { // RFP = Request For Proposal
        navigate('/requestForProposal')
    }

    const goToServices = (selectGroup) => {
        navigate(selectGroup ? `/services?service_group=${selectGroup}` : '/services')
    }

    return (
        <div className="home-page" >

            <Nav />

            <div className="hero-div" >

                <div className="back-image-frame" >
                    
                    <LazyImage 
                        className='back-image'
                        alt="prédio espelhado e uma pequena árvore a direita"
                        thumbnail = { home_page }
                        lightSource = { 'getImage/home-page.jpg' }
                        heavySource = { 'getImage/home-page.png' }
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                    />

                    <div className="cta1-container">

                        <h1 className="cta1-title">Transforme a sua empresa em um modelo de gestão ambiental</h1>

                        <div className="cta1-btn default-button" onClick={ goToRFP } >
                            Quero uma Proposta
                        </div>
                        
                    </div>

                </div>

            </div>


            <div className="home-services-container">
                <div className="title">Serviços em Destaque</div>
                
                <div className="services-grid">
                    <Service
                        title={ highlighteds ? highlighteds[0] : '' }
                        image={ 
                            <img src={ '/images/destaque1.png' } alt="destaque 1" />
                        }
                        action={ e => {
                            goToServices( highlighteds ? highlighteds[0] : null )
                        }}
                    />
                    <Service
                        title={ highlighteds ? highlighteds[1] : '' }
                        image={ 
                            <img src={ '/images/destaque2.png' } alt="destaque 2" />
                        }
                        action={ e => {
                            goToServices( highlighteds ? highlighteds[1] : null )
                        }}
                    />
                </div>

                <div className="show-more default-button" onClick={ _ => goToServices() } >
                    Todos os Serviços
                </div>
            </div>

            <div className="reach-your-goals">

                <div className="call-to-action">
                    <h1 className="title">
                        Alcance suas Metas Sustentáveis
                    </h1>

                    <h1 className="mobile-title">
                        Transforme Seus Objetivos em Realidade Sustentável
                    </h1>

                    <p className="subtitle">
                        Entre em contato conosco para mais informações.
                    </p>

                    <p className="mobile-subtitle">
                        Entre em contato e saiba em detalhes como podemos ajudar a sua empresa.
                    </p>

                    <div className="contact-us default-button" onClick={ goToContact } >
                        Fale Conosco
                    </div>
                </div>

                <div className="image-container">
                    <img src={ meta_sustentavel } alt='mão posicionando bloquinhos de madeira com símbolos de sustentabilidade' />
                </div>

            </div>

            <FeedbacksGrid feedbacks={feedbacks} />

            <div className="faq-container">

                <h1 className="faq-title">Perguntas Frequentes</h1>

                <div className="questions-grid">{ faqData.map( item =>
                    <FAQItem title={item.title} content={item.content} key={item.title} />
                )}

                </div>

            </div>

            <Footer className='footer' />

        </div>
    )
}


function FAQItem({ title, content }) {
    
    const [toggle, setToggle] = useState(false)
    const itemRef = useRef(null)

    const handleReset = (e) => {
        if (!itemRef.current) { return }

        if (!itemRef.current.contains(e.target)) {
            setToggle(false)
        }
    }
    
    useEffect(() => {
        document.addEventListener('mousedown', handleReset )

        return _ => document.removeEventListener('mousedown', handleReset)
    }, [])

    return (
        <div className="question"
            ref={ itemRef }
            onClick={ _ => setToggle(prev => !prev) }
        >
            <div className="header">
                <div className="triangle" 
                    style={ toggle ? { transform: 'rotate(90deg)' } 
                        : null
                }    
                ></div>
                <p className="title">{ title }</p>
            </div>

            <p className="content" style={ !toggle ? { display: 'none' } : null }> { content } </p>
        </div>
    )
}

function FeedbacksGrid({ feedbacks }) {

    const [mobile ,setMobile] = useState(false)

    const frameRef = useRef(null)
    const gridRef = useRef(null)
    const selectorRef = useRef(null)
    const startX = useRef(0);
    const currentSlide = useRef(0);

    const [pages, setPages] = useState(Array.from({ length: mobile ?
        feedbacks.length
        : Math.ceil(feedbacks.length / 3)
    }))

    const handleMediaChange = (e) => {
        if (e.matches) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 650px)')
        handleMediaChange(mediaQuery)

        mediaQuery.addEventListener('change', handleMediaChange)
    }, [])

    useEffect(() => {
        setPages(Array.from({ length: mobile ?
            feedbacks.length
            : Math.ceil(feedbacks.length / 3)
        }))
    }, [mobile, feedbacks.length])


    const updateSelection = useCallback((index) => {
        if (!selectorRef.current) { return; }
        const items = Array.from(selectorRef.current.children);
        items.forEach(item => item.classList.remove('selected'));
        if (items[index]) {
            items[index].classList.add('selected');
        }
    }, []);

    const handleSlide = useCallback((to) => {
        if (!(gridRef.current && frameRef)) { return }

        gridRef.current.style = `transform: translateX(-${to * frameRef.current.clientWidth}px)`;
        currentSlide.current = to
        updateSelection(to);
    }, [updateSelection])

    const handleTouchStart = useCallback((e) => {
        startX.current = e.touches[0].clientX;
    }, []);

    const handleTouchMove = useCallback((e) => {
        if (!startX.current) return;
        const moveX = e.touches[0].clientX;
        const diff = startX.current - moveX;

        if (Math.abs(diff) > 50) {
            if (diff > 0 && currentSlide.current < pages.length - 1) {
                handleSlide(currentSlide.current + 1);
            } else if (diff < 0 && currentSlide.current > 0) {
                handleSlide(currentSlide.current - 1);
            }
            startX.current = 0;
        }
    }, [handleSlide, pages.length]);

    const handleTouchEnd = useCallback(() => {
        startX.current = 0;
    }, []);

    useEffect(() => {
        const frameElement = frameRef.current;
        if (frameElement) {
            frameElement.addEventListener('touchstart', handleTouchStart);
            frameElement.addEventListener('touchmove', handleTouchMove);
            frameElement.addEventListener('touchend', handleTouchEnd);
        }
        return () => {
            if (frameElement) {
                frameElement.removeEventListener('touchstart', handleTouchStart);
                frameElement.removeEventListener('touchmove', handleTouchMove);
                frameElement.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [pages.length, handleTouchMove, handleTouchStart, handleTouchEnd]);
    
    return (
        <div className="feedbacks-container">
                
            <div className="frame" ref={ frameRef } >
                <div className="feedbacks-grid" ref={ gridRef } >{ feedbacks.map( (item, index) => 

                    <div className="feedback" key={`item${index}`}>
                        <p className="content">{item.feedback}</p>
                        <p className="author">{item.author}</p>
                    </div>

                )}</div>
            </div>

            <div className="feedbacks-container-selector" ref={ selectorRef } >{ pages.map( (_, index) => 
                <div 
                    key={index}
                    className={`item${ index === 0 ? ' selected' : ''}`}
                    onClick={ e => {
                        handleSlide(index)
                    }}
                ></div>
            )}</div>
        </div>
    )
}